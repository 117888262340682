import { LegacyAny } from '@soracom/shared/core';
import { Breadcrumb, BreadcrumbForDashboard } from '@soracom/shared/routing';

import { UCStorage } from 'apps/user-console/src/app/shared/UCStorage';

import { AirType } from '@soracom/shared/soracom-services-ui/groups-ui';

/**
 * The `LegacyRouteDefinition` exists only to support type-safety with legacy AngularJS user-console code. It doesn't make sense to invest time in this code beyond the minimum necessary, because it is AngularJS. At the same time, though, we currently (2022-07-04) do need to add an AngularJS route to match every Angular route we add. So it is important to use TypeScript here, to enforce that mandatory properties are present. (Because it would be easy to forget.)
 *
 * In modern Angular, routes have some useful abstractions to model parent-child relationships, but they are not available in legacy AngularJS.
 *
 * So we basically just verbosely and manually manage the breadcrumb hierarchies for legacy routes in this file.
 *
 * Note also that at least as of 2022-06-30, AngularJS must have a route definition for every route in the app, including Angular routes. So even when we define a route in an Angular module, we still need to define the same route here. Thus, we do *all* breadcrumb definition here, too.
 */
interface LegacyRouteDefinition {
  path: string;
  data?: {
    /**
     * For each route, define an array of breadcrumbs, or `false` to indicate the breadcrumbs should not be displayed at all for this route.
     */
    breadcrumbs: Breadcrumb[] | false;
  };
  template?: string;
  reloadOnSearch?: boolean;
  redirectTo?: string;
  resolveRedirectTo?: any;
  titleKey?: string;
}

// Some re-used breadcrumb definitions:
const DB = BreadcrumbForDashboard;
const BILLING = { labelKey: 'routes.title.billings', url: '/billings/payment_history' };
const SORA_CAM = { labelKey: 'nav.sora_cam.title', url: '/sora_cam' };
const RELAY = { labelKey: 'routes.title.relay', url: '/relay' };

type LegacyRouteDefinitionMap = LegacyRouteDefinition[];

// TITLE NOTES: Breadcrumb titles come from apps/user-console/app/shared/main/routes.en.yaml when existing data exists, but also may come from apps/user-console/app/shared/main/breadcrumbs.en.yaml if there is no existing route name or there is something special about the breadcrumb format. Also the /gadgets/xxx routes define them in the component's i18n YAML. That is not ideal (they should all be in same place), but fixing that is out of scope for this first breadcrumbs PR.

/**
 * The route definitions used to be untyped JS code passed directly into `$routeProvider.when()`. But we added type safety here to make it impossible to accidentally omit a breadcrumb definition from any legacy route. That is the reason we now build this list as a `LegacyRouteDefinitionMap` instead.
 *
 * NOTE: If you change route def in hybrid app, please update apps/user-console/src/app/main/app.routing.ts as well.
 */
// prettier-ignore
const routeDefinitions: LegacyRouteDefinitionMap = [
  // /signup
  // We used to redirect /signup to the external sign up app here, but now we do it in Angular (apps/user-console/src/app/app.routing.ts), not AngularJS.
  {
    path: '/signup',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/signup_kddi',
    data: { breadcrumbs: false },
    template: '<app-signup-kddi></app-signup-kddi>',
  },
  {
    path: '/signup_kddi_finished',
    data: { breadcrumbs: false },
    template: '<app-signup-kddi-finished></app-signup-kddi-finished>',
  },
  {
    path: '/operators/add_email_token/verify',
    data: { breadcrumbs: false },
    // This route is serviced by lazy-loaded SoracomAccountManagementModule, so the AngularJS route template should be ''.
    template: '',
  },
  {
    path: '/login',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/sam_login',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/not_allowed',
    data: { breadcrumbs: false },
    template: '<sc-not-allowed></sc-not-allowed>',
  },
  {
    path: '/mfa_reset',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/auth/mfa_reset_token/verify',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/password_reset',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/auth/password_reset_token/verify',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/password/reset',
    data: { breadcrumbs: false },
    // deprecated
    template: '',
  },
  {
    path: '/subscriber_transfer_token/verify',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/account/company_information',
    data: {
      breadcrumbs: false, // because this route just redirects
    },
  },
  {
    path: '/account/contact',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.account-contact', url: '/account/contact' }],
    },
    template: '',
  },
  {
    path: '/account/email',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.account-email', url: '/account/email' }],
    },
    template: '',
  },
  {
    path: '/account/coupons',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.account-coupons', url: '/account/coupons' }],
    },
    template: '',
  },
  {
    path: '/account/shipping_addresses',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.account-shipping_addresses', url: '/account/shipping_addresses' }],
    },
    template: '',
  },
  {
    path: '/account/notification_destinations',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.account-notification-destinations', url: '/account/notification_destinations' },
      ],
    },
    template: '',
  },
  {
    path: '/support_login',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/support_logout',
    data: { breadcrumbs: false },
    template: '<app-support-logout></app-support-logout>',
  },
  {
    path: '/support/plans',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.paidSupport', url: '/support/plans' }],
    },
    template: '',
  },
  {
    path: '/dashboard',
    data: {
      breadcrumbs: [DB],
    },
    // Mason 2022-07-05: This doesn't work — it just makes / → "not allowed" page. So we fix the case for users not allowed to see Dashboard in BreadcrumbsService.
    // featureName: 'dashboard',
    template: '',
  },
  // mason 2023-05-01: Are breadcrumbs really still configured in this AngularJS routes file?!
  {
    path: '/sims',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.sims', url: '/sims' }],
    },
    template: '',
    titleKey: 'sims',
  },
  {
    path: '/legacy_sims',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.sims', url: '/legacy_sims' }],
    },
    template: '',
    titleKey: 'sims',
  },
  {
    path: '/sims/:simId/subscriptions',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.sims', url: '/sims' },
        {
          labelKey: 'routes.title.sim_details',
          url: '/sims/:simId',
          queryParams: { from: '/sims/:simId/subscriptions' },
          expandPathParamsInUrl: true,
          // @mason 2023-05-22: So this is really not glorious code, but real life. What is happening here? Why is this so weird? The reason is that our legacy app routing has a lot of very deep and tangled history, and we have to extend this legacy code in 2023 to enable breadcrumbs to point to a route with query parameters, and have those query parameters expanded. This Breadcrumb + legacy AngularJS route code is a mess, but it's a mess that we can't easily escape from so I have to extend it. This extra data is going to make LegacyAngularJsRouteEvent able to convert this to the appropriate breadcrumb that SIM Details UI will be able to discern where it should navigate back to when it closes (here, this extra data is telling it to go back to the Manage Subscriptions page).
          expandPathParamsInQueryParamsValues: ['from'],
        },
        { labelKey: 'routes.title.sim_subscriptions', url: '/sims/:simId/subscriptions', expandPathParamsInUrl: true },
      ],
    },
    titleKey: 'sim_details',
  },
  {
    path: '/sims/:simId',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.sims', url: '/sims' },
        { labelKey: 'routes.title.sim_details', url: '/sims/:simId', expandPathParamsInUrl: true },
      ],
    },
    titleKey: 'sim_details',
  },
  {
    path: '/subscribers-legacy',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.subscribers', url: '/subscribers-legacy' }],
    },
    template: "<sc-subscribers api-mode='subscriber'></sc-subscribers>",
    titleKey: 'subscribers',
  },
  {
    path: '/subscribers',
    // redirectTo: '/sims/?redirectFromSubscribers="true"',
    template: '',
    data: {
      breadcrumbs: [DB],
    },
  },
  {
    path: 'switch-user',
    template: '',
    data: {
      breadcrumbs: [DB],
    },
  },
  {
    path: 'switch-user/back',
    template: '',
    data: {
      breadcrumbs: [DB],
    },
  },
  // TODO: Keeping this for showing old UI as users are not used to new UI of event handler yet
  // Remove this after we decide to keep only new UI
  {
    path: '/watch',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.watch', url: '/watch' }],
    },
    template: '',
  },
  {
    path: '/watch/subscribers',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.watch', url: '/watch' }],
    },
    titleKey: 'watch-subscribers',
    template: '',
  },
  {
    path: '/watch/subscribers/:imsi',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.watch', url: '/watch' }],
    },
    titleKey: 'watch-subscribers',
    template: '',
  },
  {
    path: '/event_handler/edit_event/:handlerId',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'breadcrumbs.Event handler', url: '/event_handler' },
        { labelKey: 'breadcrumbs.Edit event', url: '/event_handler/edit_event/' },
      ],
    },
    template: '',
  },
  {
    path: '/event_handler/edit_event',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'breadcrumbs.Event handler', url: '/event_handler' },
        { labelKey: 'breadcrumbs.Edit event', url: '/event_handler/edit_event/' },
      ],
    },
    template: '',
  },
  {
    path: '/event_handler',
    data: {
      breadcrumbs: [DB, { labelKey: 'breadcrumbs.Event handler', url: '/event_handler' }],
    },
    template: '',
  },
  {
    path: '/groups',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.groups', url: '/groups' }],
    },
    template: '<sc-groups air-type="' + AirType.cellular + '"></sc-groups>',
    reloadOnSearch: false,
  },
  {
    path: '/groups/:id',
    // Mason 2022-07-01: There is some confusion around this route. The URL is /groups/:id, but we don't always use that kind of URL. We sometimes use it and sometimes use /groups?id=groupId. I don't know why but that is out of scope for my current PR, so I just note the fact.
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.groups', url: '/groups' },
        // This is kind of a hack, for "loading..." as the final breadcrumb name will be set by GroupsController after the route loads.
        { labelKey: '...' },
      ],
    },
    template: '<sc-groups air-type="' + AirType.cellular + '"></sc-groups>',
    titleKey: 'group_detail',
  },
  {
    path: '/relay',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.relay-sessions', url: '/relay/sessions' }],
    },
    template: '',
  },
  {
    path: '/relay/sessions',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.relay-sessions', url: '/relay/sessions' }],
    },
    template: '',
  },
  {
    path: '/relay/sessions/new',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.relay-sessions', url: '/relay/sessions' },
        { labelKey: 'routes.title.relay-sessions-new', url: '/relay/sessions/new' },
      ],
    },
    template: '',
  },
  {
    path: '/relay/sessions/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.relay-sessions', url: '/relay/sessions' },
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
  },
  {
    path: '/relay/limited_preview',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.relay-limited_preview', url: '/relay/limited_preview' }],
    },
    template: '',
  },
  {
    path: '/billings/payment_history',
    data: {
      breadcrumbs: [DB, BILLING, { labelKey: 'routes.title.billing_and_payment_history', url: '/billings' }],
    },
    template: '',
  },
  {
    path: '/billings/billing_dashboard',
    data: {
      breadcrumbs: [DB, BILLING, { labelKey: 'routes.title.billingDashboard', url: '/billings/billing_dashboard' }],
    },
    template: '',
  },
  {
    path: '/billings/billing_alert',
    data: {
      breadcrumbs: [DB, BILLING, { labelKey: 'breadcrumbs.Billing alert', url: '/billings/billing_alert' }],
    },
    template: '',
  },
  {
    path: '/billings/payment_settings',
    data: {
      breadcrumbs: [DB, BILLING, { labelKey: 'routes.title.payments', url: '/billings/payment_settings' }],
    },
    template: '',
  },
  {
    path: '/orders',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.orders', url: '/orders' }],
    },
    template: '',
  },
  {
    path: '/orders/new',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.orders', url: '/orders' },
        { labelKey: 'breadcrumbs.New order', url: '/orders/new' },
      ],
    },
    template: '',
  },
  {
    path: '/esim-profile-orders',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.orders', url: '/orders' },
        { labelKey: 'routes.title.esim_profile_orders', url: '/esim-profile-orders' },
      ],
    },
    template: '',
  },
  {
    path: '/esim-profile-orders/order',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.orders', url: '/orders' },
        { labelKey: 'routes.title.esim_profile_orders', url: '/esim-profile-orders' },
        { labelKey: 'routes.title.esim_qr_code_order', url: '/esim-profile-orders/order' },
      ],
    },
    template: '',
  },
  {
    path: '/usage-statistics',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.usage-statistics', url: '/usage-statistics' }],
    },
    template: '',
  },
  {
    path: '/service_usage_history',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.service_usage_history', url: '/service_usage_history' }],
    },
    template: '',
  },
  {
    path: '/security/:page/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.security', url: '/security' },
        // This is some weird looking stuff, but what happens is that the route path parameters get replaces **in the labelKey itself** before i18n processing is done. See Breadcrumb and LegacyAngularJsRouteEvent for details.
        {
          expandPathParams: true,
          expandPathParamsInUrl: true,
          labelKey: 'breadcrumbs./security/:page',
          url: '/security/:page',
        },
        // This next one is similar, but it uses label and not labelKey. This lets us just display the runtime value of :id as the breadcrumb label (which is appropriate in this case, since it is a user-defined username like "mason-test").
        { expandPathParams: true, expandPathParamsInUrl: true, label: ':id', url: '/security/:page/:id' },
      ],
    },
    template: '',
  },
  {
    path: '/security/:page',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.security', url: '/security' },
        {
          expandPathParams: true,
          expandPathParamsInUrl: true,
          labelKey: 'breadcrumbs./security/:page',
          url: '/security/:page',
        },
      ],
    },
    template: '',
  },
  {
    path: '/security',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.security', url: '/security' }],
    },
    template: '',
  },
  {
    path: '/tos',
    data: { breadcrumbs: false },
    template: '',
  },
  {
    path: '/virtual_private_gateways',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.vpg', url: '/virtual_private_gateways' }],
    },
    template: '',
  },
  {
    path: '/virtual_private_gateways/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.vpg', url: '/virtual_private_gateways' },
        // Groups overrides the breadcrumb to show "name (id)" but the existing behavior for VPG is just show the ID, so I am sticking with that for now. FIXME: Fix this if there is time...
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
  },
  {
    path: '/lagoon',
    data: {
      breadcrumbs: [
        DB,
        // Lagoon component will override breadcrumbs, so this dumb default is OK here:
        { labelKey: 'nav.lagoon', url: '/lagoon' },
      ],
    },
    template: `
<sc-base "name"="Lagoon">
  <app-lagoon-page page-id="index">
    <app-lagoon></app-lagoon>
  </app-lagoon-page>
</sc-base>`,
  },
  {
    path: '/lagoon/dashboards',
    data: { breadcrumbs: false /* provided by LagoonPageComponent */ },
    template: `
<sc-base name="LagoonDashboards">
  <app-lagoon-page page-id="dashboards">
    <div class="ds-rows">
      <h2 translate="LagoonPageComponent.tab.dashboards.title"></h2>
      <app-lagoon-dashboards></app-lagoon-dashboards>
    </div>
  </app-lagoon-page>
</sc-base>`,
  },
  {
    path: '/lagoon/users',
    data: { breadcrumbs: false /* provided by LagoonPageComponent */ },
    template: `
<sc-base name="LagoonUsers">
  <app-lagoon-page page-id="users">
    <div class="ds-rows">
      <h2 translate="LagoonPageComponent.tab.users.title"></h2>
      <app-lagoon-users></app-lagoon-users>
    </div>
  </app-lagoon-page>
</sc-base>`,
  },
  {
    path: '/lagoon/subscription',
    data: { breadcrumbs: false /* provided by LagoonPageComponent */ },
    template: `
<sc-base name="LagoonSubscription">
  <app-lagoon-page page-id="subscription">
    <app-lagoon-plan></app-lagoon-plan>
    <app-lagoon-license-packs></app-lagoon-license-packs>
  </app-lagoon-page>
</sc-base>`,
  },
  {
    path: '/lagoon/customize',
    data: { breadcrumbs: false /* provided by LagoonPageComponent */ },
    template: `
<sc-base name="LagoonCustomize">
  <app-lagoon-page page-id="customize">
    <div class="ds-rows">
      <h2 translate="LagoonPageComponent.tab.customize.title"></h2>
      <app-lagoon-logo></app-lagoon-logo>
    </div>
  </app-lagoon-page>
</sc-base>`,
  },
  {
    path: '/error_logs',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.logs', url: '/error_logs' }],
    },
    template: '<sc-logs></sc-logs>',
    reloadOnSearch: false,
  },
  {
    path: '/audit_logs',
    data: {
      breadcrumbs: [DB, { labelKey: 'breadcrumbs.Audit logs', url: '/audit_logs' }],
    },
    template: `<sc-base name="auditLogs"><app-log-viewer mode="auditLogs"></app-log-viewer></sc-base>`,
    reloadOnSearch: false,
  },
  {
    path: '/napter_audit_logs',
    data: {
      breadcrumbs: [DB, { labelKey: 'breadcrumbs.Napter audit logs', url: '/napter_audit_logs' }],
    },
    template: '<sc-audit-logs-page></sc-audit-logs-page>',
    reloadOnSearch: false,
  },
  {
    path: '/data',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.harvest_data', url: '/data' }],
    },
    template: '<sc-harvest-data></sc-harvest-data>',
    reloadOnSearch: false,
  },
  {
    path: '/harvest_data',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.harvest_data', url: '/data' }],
    },
    template: '<sc-harvest-data></sc-harvest-data>',
    reloadOnSearch: false,
  },
  {
    path: '/harvest_files',
    data: {
      // HarvestFilesPageController will override these breadcrumbs as use navigates Harvest files:
      breadcrumbs: [DB, { labelKey: 'routes.title.harvest_files', url: '/harvest_files' }],
    },
    template: '<sc-harvest-files></sc-harvest-files>',
    reloadOnSearch: false,
  },
  {
    path: '/lora_devices',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.lora_devices', url: '/lora_devices' }],
    },
    template: '<sc-lora-devices></sc-lora-devices>',
  },
  {
    path: '/lora_device_groups',
    data: {
      // breadcrumbs will be updated by GroupsController:
      breadcrumbs: [DB, { labelKey: 'routes.title.lora_device_groups', url: '/lora_device_groups' }],
    },
    template: '<sc-groups air-type="' + AirType.lorawan + '"></sc-groups>',
    reloadOnSearch: false,
  },
  {
    path: '/lora_device_groups/:id',
    data: {
      // breadcrumbs will be updated by GroupsController, so this basic def is OK:
      breadcrumbs: [DB, { labelKey: 'routes.title.lora_device_groups', url: '/lora_device_groups' }],
    },
    template: '<sc-groups air-type="' + AirType.lorawan + '"></sc-groups>',
  },
  {
    path: '/lora_gateways',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.lora_gateways', url: '/lora_gateways' }],
    },
    template: '<sc-lora-gateways></sc-lora-gateways>',
  },
  {
    path: '/lora_gateways/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.lora_gateways', url: '/lora_gateways' },
        // FIXME if there is time: existing implementation uses gateway NAME, not ID...
        { expandPathParams: true, label: ':id' },
      ],
    },
    titleKey: 'lora_gateways',
    template: '<sc-lora-gateway></sc-lora-gateway>',
  },
  {
    path: '/lora_network_sets',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.lora_network_sets', url: '/lora_network_sets' }],
    },
    template: '<sc-lora-network-sets></sc-lora-network-sets>',
  },
  {
    path: '/lora_network_sets/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.lora_network_sets', url: '/lora_network_sets' },
        // FIXME if there is time: existing implementation uses gateway NAME, not ID...
        { expandPathParams: true, label: ':id' },
      ],
    },
    titleKey: 'lora_network_sets',
    template: '<sc-lora-network-set></sc-lora-network-set>',
  },
  {
    path: '/sigfox_devices',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.sigfox_devices', url: '/sigfox_devices' }],
    },
    template: '<sc-sigfox-devices></sc-sigfox-devices>',
  },
  {
    path: '/sigfox_device_groups',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.sigfox_device_groups', url: '/sigfox_device_groups' }],
    },
    template: '<sc-groups air-type="' + AirType.sigfox + '"></sc-groups>',
    reloadOnSearch: false,
  },
  {
    path: '/sigfox_device_groups/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.sigfox_device_groups', url: '/sigfox_device_groups' },
        // GroupsComponent will update the breadcrumbs:
        { label: '...' },
      ],
    },
    template: '<sc-groups air-type="' + AirType.sigfox + '"></sc-groups>',
  },
  {
    path: '/satellite_device_groups',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.satellite_device_groups', url: '/satellite_device_groups' }],
    },
    template: '<sc-groups air-type="' + AirType.satellite + '"></sc-groups>',
    reloadOnSearch: false,
  },
  {
    path: '/satellite_device_groups/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.satellite_device_groups', url: '/satellite_device_groups' },
        // GroupsComponent will update the breadcrumbs:
        { label: '...' },
      ],
    },
    template: '<sc-groups air-type="' + AirType.satellite + '"></sc-groups>',
  },
  {
    path: '/batch',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/groups',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/groups/:groupId',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/groups/:groupId/jobs',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/groups/:groupId/jobs/:jobId',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/jobs',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/batch/jobs/:jobId',
    data: {
      breadcrumbs: [],
    },
    template: '',
  },
  {
    path: '/soralets',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.soralets', url: '/soralets' }],
    },
    template: '',
  },
  {
    path: '/soralets/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.soralets', url: '/soralets' },
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
  },
  {
    path: '/long_term_discounts',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.long_term_discounts', url: '/long_term_discounts' }],
    },
    template: '<sc-long-term-discounts></sc-long-term-discounts>',
    // Mason 2017-05-11: Using '<foo></foo>' to specify top level component
    // seems clumsy and error-prone, but it seems(?) to be the only way to route
    // to a top-level component with ngRoute.
  },
  {
    path: '/inventory_devices',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.inventory_devices', url: '/inventory_devices' }],
    },
    template: '<sc-devices></sc-devices>',
  },
  {
    path: '/inventory_devices/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'routes.title.inventory_devices', url: '/inventory_devices' },
        // FIXME if there is time: existing implementation uses $ctrl.device.endpoint || $ctrl.device.deviceId
        { expandPathParams: true, label: ':id' },
      ],
    },
    titleKey: 'inventory_devices',
    template: '<sc-device></sc-device>',
  },
  {
    path: '/inventory_device_groups',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.inventory_device_groups', url: '/inventory_device_groups' }],
    },
    template: '<sc-groups air-type="device"></sc-groups>',
    reloadOnSearch: false,
  },
  {
    path: '/inventory_device_groups/:id',
    data: { breadcrumbs: false }, // GroupsComponent will update the breadcrumbs
    titleKey: 'inventory_device_groups',
    template: '<sc-groups air-type="device"></sc-groups>',
  },
  {
    path: '/device_object_models',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.device_object_models', url: '/device_object_models' }],
    },
    template: '<sc-device-object-models></sc-device-object-models>',
  },
  {
    path: '/devices',
    redirectTo: '/inventory_devices',
  },
  {
    path: '/devices/:id',
    redirectTo: '/inventory_devices/:id',
  },
  {
    path: '/device_groups',
    redirectTo: '/inventory_device_groups',
  },
  {
    path: '/device_groups/:id',
    redirectTo: '/inventory_device_groups/:id',
  },
  {
    path: '/traffic_volume_ranking',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.traffic_volume_ranking', url: '/traffic_volume_ranking' }],
    },
    template: '<sc-traffic-volume-ranking></sc-traffic-volume-ranking>',
  },
  {
    path: '/terminate_account',
    data: {
      breadcrumbs: [DB, { labelKey: 'routes.title.terminate_account', url: '/terminate_account' }],
    },
    template: '',
  },
  {
    path: '/buttons',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.gadgets.aws_button', url: '/gadgets/aws_button' }],
    },
    template: '',
  },
  {
    path: '/gadgets/aws_button',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.gadgets.aws_button', url: '/gadgets/aws_button' }],
    },
    template: '',
  },
  {
    path: '/gadgets/lte_m_button',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.gadgets.lte_m_button', url: '/gadgets/lte_m_button' }],
    },
    template: '<sc-lte-m-button-page path="index"></sc-lte-m-button-page>',
  },
  {
    path: '/gadgets/lte_m_button/new',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.gadgets.lte_m_button', url: '/gadgets/lte_m_button' },
        { labelKey: 'gadgets.lte_m_button_page.breadcrumb.newConfig', url: '/gadgets/lte_m_button/new' },
      ],
    },
    template: '<sc-lte-m-button-page path="new"></sc-lte-m-button-page>',
  },
  {
    path: '/gadgets/lte_m_button/:groupId/edit',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.gadgets.lte_m_button', url: '/gadgets/lte_m_button' },
        { labelKey: 'gadgets.lte_m_button_page.breadcrumb.editConfig', url: '/gadgets/lte_m_button/new' },
      ],
    },
    template: '<sc-lte-m-button-page path="edit"></sc-lte-m-button-page>',
  },
  {
    path: '/gadgets/gps_multiunit',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.gadgets.gps_multiunit', url: '/gadgets/gps_multiunit' }],
    },
    template: '<sc-gps-multiunit-page path="index"></sc-gps-multiunit-page>',
  },
  {
    path: '/gadgets/gps_multiunit/new',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.gadgets.gps_multiunit', url: '/gadgets/gps_multiunit' },
        { labelKey: 'gadgets.gps_multiunit_page.breadcrumb.selectSubscribers', url: '/gadgets/gps_multiunit/new' },
      ],
    },
    template: '<sc-gps-multiunit-page path="new"></sc-gps-multiunit-page>',
  },
  {
    path: '/gadgets/gps_multiunit/:groupId/edit',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.gadgets.gps_multiunit', url: '/gadgets/gps_multiunit' },
        { labelKey: 'gadgets.gps_multiunit_page.breadcrumb.editConfig', url: '/gadgets/gps_multiunit/new' },
      ],
    },
    template: '<sc-gps-multiunit-page path="edit"></sc-gps-multiunit-page>',
  },
  {
    path: '/debug',
    data: { breadcrumbs: false },
    template: '<sc-debug favorite-color="brown"></sc-debug>',
  },
  {
    path: '/sample',
    data: {
      breadcrumbs: [DB, { label: 'sample page', url: '/sample' }],
    },
    template: '',
  },
  {
    path: '/sora_cam',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        // Currently, '/sora_cam' redirects to devices page
        { labelKey: 'SoraCamPageComponent.tab.devices.title', url: '/sora_cam/devices' },
        // { labelKey: 'SoraCamPageComponent.tab.index.title', url: '/sora_cam' }
      ],
    },
    template: '',
  },
  {
    path: '/sora_cam/cellular_packs',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.cellular_packs.title', url: '/sora_cam/cellular_packs' },
      ],
    },
    template: '',
  },
  {
    path: '/sora_cam/events',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamPageComponent.tab.events.title', url: '/sora_cam/events' }],
    },
    template: '',
  },
  {
    path: '/sora_cam/licenses',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamPageComponent.tab.licenses.title', url: '/sora_cam/licenses' }],
    },
    template: '',
  },
  {
    path: '/sora_cam/devices',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamPageComponent.tab.devices.title', url: '/sora_cam/devices' }],
    },
    template: '',
  },
  {
    path: '/sora_cam/devices/:id',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.devices.title', url: '/sora_cam/devices' },
        // FIXME It should be show device name with path.
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
    titleKey: 'sora_cam_device_detail',
  },
  {
    path: '/sora_cam/devices/:id/regular_interval_images',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.devices.title', url: '/sora_cam/devices' },
        // FIXME It should be show device name with path.
        { expandPathParams: true, label: ':id', url: '/sora_cam/devices' }, //TODO url
        { labelKey: 'SoraCamRegularIntervalImagesComponent.title' },
      ],
    },
    template: '',
    titleKey: 'sora_cam_regular_interval_images',
  },
  {
    path: '/sora_cam/multi_device_images',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamMultiDeviceImagesComponent.title' }],
    },
    template: '',
    titleKey: 'sora_cam_multiple_device_images',
  },
  {
    path: '/sora_cam/multi_device_live_streamings',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamMultiDeviceLiveStreamingsComponent.title' }],
    },
    template: '',
    titleKey: 'sora_cam_multiple_device_live_streamings',
  },
  {
    path: '/sora_cam/multi_device_cloud_recordings',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamMultiDeviceCloudRecordingsComponent.title' }],
    },
    template: '',
    titleKey: 'sora_cam_multiple_device_cloud_recordings',
  },
  {
    path: '/sora_cam/image/exports',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.image_exports.title', url: '/sora_cam/image/exports' },
      ],
    },
    template: '',
  },
  {
    path: '/sora_cam/video/exports',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.video_exports.title', url: '/sora_cam/video/exports' },
      ],
    },
    template: '',
  },
  {
    path: '/sora_cam/shares',
    data: {
      breadcrumbs: [DB, SORA_CAM, { labelKey: 'SoraCamPageComponent.tab.device_share.title', url: '/sora_cam/shares' }],
    },
    template: '',
    titleKey: 'sora_cam_device_share',
  },
  {
    path: '/sora_cam/limited_preview/annual_payment',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.annual_payment.title', url: '/sora_cam/limited_preview/annual_payment' },
      ],
    },
    template: '',
    titleKey: 'sora_cam_limited_preview_annual_payment',
  },
  {
    path: '/sora_cam/limited_preview/live_streaming',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        { labelKey: 'SoraCamPageComponent.tab.live_streaming.title', url: '/sora_cam/limited_preview/live_streaming' },
      ],
    },
    template: '',
    titleKey: 'sora_cam_limited_preview_live_streaming',
  },
  {
    path: '/sora_cam/notification_configurations',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.title',
          url: '/sora_cam/notification_configurations',
        },
      ],
    },
    template: '',
    titleKey: 'sora_cam-notification_configurations',
  },
  {
    path: '/sora_cam/notification_configurations/device_management',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.title',
          url: '/sora_cam/notification_configurations',
        },
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.device_management.title',
          url: '/sora_cam/notification_configurations/device_management',
        },
      ],
    },
    template: '',
    titleKey: 'sora_cam-notification_configurations-device_management',
  },
  {
    path: '/sora_cam/notification_configurations/device_status',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.title',
          url: '/sora_cam/notification_configurations',
        },
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.device_status.title',
          url: '/sora_cam/notification_configurations/device_status',
        },
      ],
    },
    template: '',
    titleKey: 'sora_cam-notification_configurations-device_status',
  },
  {
    path: '/sora_cam/notification_configurations/device_events',
    data: {
      breadcrumbs: [
        DB,
        SORA_CAM,
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.title',
          url: '/sora_cam/notification_configurations',
        },
        {
          labelKey: 'SoraCamPageComponent.tab.notification_configurations.device_events.title',
          url: '/sora_cam/notification_configurations/device_events',
        },
      ],
    },
    template: '',
    titleKey: 'sora_cam-notification_configurations-device_events',
  },
  {
    path: '/satellite_devices/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.satellite_devices', url: '/satellite_devices' },
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
  },
  {
    path: '/satellite_devices',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.satellite_devices', url: '/satellite_devices' }],
    },
    template: '',
  },
  {
    path: '/flux/apps/:id',
    data: {
      breadcrumbs: [
        DB,
        { labelKey: 'nav.flux', url: '/flux' },
        { labelKey: 'nav.flux_apps', url: '/flux/apps' },
        { expandPathParams: true, label: ':id' },
      ],
    },
    template: '',
  },
  {
    path: '/flux/apps',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.flux', url: '/flux' }, { labelKey: 'nav.flux_apps', url: '/flux/apps' }],
    },
    template: '',
  },
  {
    path: '/flux/credits',
    data: {
      breadcrumbs: [DB, { labelKey: 'nav.flux', url: '/flux' }, { labelKey: 'nav.flux_credits', url: '/flux/credits' }],
    },
    template: '',
  },
];

export const routes = ($routeProvider: LegacyAny) => {
  // debugger;
  for (const routeDef of routeDefinitions) {
    const path = routeDef.path;
    // @ts-expect-error (legacy code incremental fix)
    delete routeDef.path;
    $routeProvider.when(path, routeDef);
  }
  $routeProvider.otherwise({
    resolveRedirectTo: UCStorage.simManagementPath,
  });
};

routes.$inject = ['$routeProvider'];
