<div class="ds-modal">
  <div class="ds-dialog">
    <header class="ds-banner">
      <div>
        <h2>{{ 'SoracomLogs.logDetail' | translate }}</h2>
      </div>
    </header>
    <section>
      <pre class="--code"><code>{{ recordDetail }}</code></pre>
    </section>
    <footer>
      <button class="ds-button" (click)="close.emit()">
        <span>{{ 'common.close' | translate }}</span>
      </button>
    </footer>
  </div>
</div>
