import { createAction, props, union } from '@ngrx/store';

import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { GpsMultiunitConfig } from '@user-console/feature-gps-multunit';

export const applyRouteParameters = createAction(
  '[Gps Multiunit] Apply Route Parameters',
  props<{ imsiList: string[] | null; groupId: string | null; newGroupName: string | null; path: string }>(),
);

//
// Actions on index page
//

export const configureNewDevice = createAction('[Gps Multiunit] Configure New Device');

export const editGpsMultiunitConfiguration = createAction(
  '[Gps Multiunit] Edit Gps Multiunit Configuration',
  props<{ groupId: string }>(),
);

export const initializeGroup = createAction('[Gps Multiunit] Initialize Group', props<{ name: string }>());

export const loadConfiguredSubscribers = createAction('[Gps Multiunit] Load Configured Subscribers Request');

export const loadConfiguredSubscribersSuccess = createAction('[Gps Multiunit] Load Configured Subscribers Success');

export const loadConfiguredSubscribersError = createAction(
  '[Gps Multiunit] Load Configured Subscribers Failure',
  (error: any) => ({ error }),
);

export const loadAvailableSubscribersSuccess = createAction('[Gps Multiunit] Load Available Subscribers Success');

export const loadAvailableSubscribersError = createAction(
  '[Gps Multiunit] Load Available Subscribers Failure',
  (error: any) => ({ error }),
);

export const loadSelectedSubscribers = createAction(
  '[Gps Multiunit] Load Selected Subscribers Request',
  props<{ imsiList: string[] }>(),
);

export const loadSelectedSubscribersSuccess = createAction(
  '[Gps Multiunit] Load Selected Subscribers Success',
  (payload: ExtendedSubscriberInterface[]) => ({ payload }),
);

export const loadSelectedSubscribersError = createAction(
  '[Gps Multiunit] Load Selected Subscribers Failure',
  (error: any) => ({ error }),
);

export const loadGroup = createAction('[Gps Multiunit] Load Group Request', props<{ groupId: string }>());

export const loadGroupSuccess = createAction('[Gps Multiunit] Load Group Success', (group: Group) => ({ group }));

export const loadGroupError = createAction('[Gps Multiunit] Load Group Failure', (error: any) => ({ error }));

export const saveConfiguration = createAction(
  '[Gps Multiunit] Save configuration',
  props<{ group: Group; newConfig: GpsMultiunitConfig; isSoracomHarvestEnabled: boolean }>(),
);

export const selectGroup = createAction('[Gps Multiunit] Select Group', props<{ imsiList: string[] }>());

export const navigateToIndexPage = createAction('[Gps Multiunit] Navigate to Index Page');

export const navigateToSelectGroupPage = createAction('[Gps Multiunit] Navigate to Select Group Page');

export const navigateToSelectSubscribersPage = createAction('[Gps Multiunit] Back to select subscribers page');

export const newConfiguration = createAction(
  '[Gps Multiunit] Edit new configuration',
  props<{ newGroupName: string }>(),
);

export const unknownError = createAction('[Gps Multiunit] Unknown Failure', (error: any) => ({ error }));

export const noop = createAction('[Gps Multiunit] noop');

const actions = union({
  applyRouteParameters,
  configureNewDevice,
  editGpsMultiunitConfiguration,
  initializeGroup,
  loadConfiguredSubscribers,
  loadConfiguredSubscribersSuccess,
  loadConfiguredSubscribersError,
  loadAvailableSubscribersSuccess,
  loadAvailableSubscribersError,
  loadSelectedSubscribers,
  loadSelectedSubscribersSuccess,
  loadSelectedSubscribersError,
  loadGroup,
  loadGroupSuccess,
  loadGroupError,
  navigateToIndexPage,
  navigateToSelectGroupPage,
  navigateToSelectSubscribersPage,
  newConfiguration,
  noop,
  selectGroup,
  unknownError,
});

export type ActionsUnion = typeof actions;
