import { LegacyAny } from '@soracom/shared/core';

import { getCoverageType, getOperatorData } from '@soracom/shared/data-access-auth';
import { Contract } from '@soracom/shared/soracom-platform';
import { sortBy } from 'lodash-es';
import { LagoonMigrator } from '../../../src/app/lagoon/lagoon_migration_status';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { LagoonLicenseQuota } from '../core/lagoon_license_quota';
import { LagoonPlan, LagoonSubscription } from './lagoon_plans';

export interface LagoonActivationRequest {
  plan: LagoonPlan;
  userPassword: string;
}

export enum LagoonLicensePackType {
  BasicUser = 'basicUser',
  BasicDashboard = 'basicDashboard',
}

export const LAGOON_VERSIONS = ['classic', 'v2', 'v3'] as const;

export type LagoonVersion = typeof LAGOON_VERSIONS[number];

export interface LagoonLicensePackUpdatingRequest {
  licensePackName: LagoonLicensePackType;
  desiredQuantity: number;
}

export class LagoonService {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService'];

  constructor(
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
  ) {}

  register(req: LagoonActivationRequest, classic?: boolean) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/lagoon/register`,
      contentType: 'application/json',
      body: req,
    };

    if (classic) {
      apiParams.query = { classic: 'true' };
    }

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  terminate() {
    const apiParams = {
      method: 'post',
      path: `/v1/lagoon/terminate`,
      contentType: 'application/json',
    };
    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  changePlan(plan: LagoonPlan) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/lagoon/plan`,
      contentType: 'application/json',
      body: {
        plan,
      },
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  getMigrationStatus(): Promise<LagoonMigrator.LagoonMigrationStatus> {
    const apiParams = {
      method: 'get',
      path: '/v1/lagoon/migration',
    };
    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => res.data);
  }

  executeMigration(body?: LagoonMigrator.MigrationPostRequest) {
    const apiParams = {
      method: 'post',
      path: '/v1/lagoon/migration',
      contentType: 'application/json',
      body,
    };
    let data: LegacyAny;
    return this.soracomApi
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => {
        data = res.data;
        return this.soracomApi.renewToken();
      })
      .then(() => {
        return data;
      });
  }

  /**
   * Lagoon subscription format like this in JWT Token
   *
   * {
   *   "operator": {
   *     "coverageTypeAttributes": {
   *       "jp": {
   *         "contracts": [
   *           "lagoon"
   *         ],
   *         "contractDetail": {
   *           "lagoon": {
   *             "availableVersions": ["classic", "v2"],
   *             "plan": "maker",
   *             "viewerMaxLimit": 9,
   *             "editorMaxLimit": 1,
   *             "alertMaxLimit": 10
   *           }
   *         }
   *       }
   *     }
   *   }
   * }
   */
  getSubscription(): LagoonSubscription {
    const plan = this.getPlan();
    if (plan === LagoonPlan.free) {
      return LagoonSubscription.free;
    } else if (plan === LagoonPlan.maker) {
      return LagoonSubscription.maker;
    } else if (plan === LagoonPlan.pro) {
      return LagoonSubscription.pro;
    } else {
      return LagoonSubscription.none;
    }
  }

  getPlan(): LagoonPlan {
    const lagoon: Contract = 'lagoon';
    const coverageType = getCoverageType();
    const contracts = getOperatorData().getContracts(coverageType) ?? [];
    const contractDetail = getOperatorData().getContractDetail(coverageType, lagoon);
    if (contracts !== null && contracts.includes(lagoon)) {
      return contractDetail.plan;
    } else {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  }

  getAvailableVersions(): LagoonVersion[] {
    const lagoon: Contract = 'lagoon';
    const coverageType = getCoverageType();
    const contracts = getOperatorData().getContracts(coverageType) ?? [];
    const contractDetail = getOperatorData().getContractDetail(coverageType, lagoon);
    // contracts should exists.
    if (contracts && contracts.includes(lagoon)) {
      return sortBy(contractDetail?.availableVersions || []);
    } else {
      return [];
    }
  }

  getLicensePackCount() {
    const apiParams = {
      method: 'get',
      path: `/v1/lagoon/license_packs`,
      contentType: 'application/json',
    };
    return this.soracomApi.callApiWithToken(apiParams);
  }

  getQuota() {
    const plan = this.getPlan();
    if (plan === LagoonPlan.pro) {
      return this.getLicensePackCount().then((res: LegacyAny) => {
        return new LagoonLicenseQuota(plan, res.data);
      });
    } else {
      return this.$q.resolve(new LagoonLicenseQuota(plan));
    }
  }

  setLicensePackCount(req: LagoonLicensePackUpdatingRequest[]) {
    const apiParams: SoracomApiParams = {
      method: 'put',
      path: `/v1/lagoon/license_packs`,
      contentType: 'application/json',
      body: {
        licensePackQuantities: req,
      },
    };

    return this.soracomApi.callApiWithToken(apiParams).then(() => {
      return this.soracomApi.renewToken();
    });
  }

  activateLagoonV2() {
    return this.executeMigration();
  }
}
