<div class="ds-modal">
  <div class="ds-dialog">
    <header class="ds-banner">
      <div>
        <h2>{{ dialogTitle | translate }}</h2>
      </div>
    </header>
    <section [innerHTML]="dialogBody | translate">
    </section>
    <footer>
      <button class="ds-button --primary" (click)="closeWithUserConfirmation()">
        <span>{{ dialogConfirmButtonTitle | translate }}</span>
      </button>
      <button class="ds-button" (click)="closeWithoutUserConfirmation()">
        <span>{{ 'generic.button_title.cancel' | translate }}</span>
      </button>
    </footer>
  </div>
</div>
