import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SoracomUserConsole } from './shared/SoracomUserConsole';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './app.component.html',
})
export class AppComponent {
  /**
   Because this legacy app loads AngularJS and Angular, and therefore has multiple top-level entities in the index.html file (ng-view for AngularJS, app-main for Angular, header, footer), the initial 'loading...' state is not simple.

   Therefore, the UI is hidden in the HTML (with `style="display: none;"), until the Angular app has fully loaded, after which point this method must be called once, to show the UI. This works better than trying to show some progress indicator, since there are multiple entities that render (which can cause glitch like 'header renders first, then replaced by global loading spinner') and also we don't need/want a spinner, since the lag here is normally very short, but is a function of the user's internet connection speed (hence can be unpredictably slow, randomly, but only when the user is on a slow connection so initial blank screen is not a problem).

   NOTE: This isn't the best general solution for Angular apps — it's a bespoke solution for the legacy user-console and its unusual loading behavior.
   */
  private removeInitialLoadingUiState(): void {
    const appRootElement = document.getElementById('app-root');
    if (!appRootElement) {
      throw new Error('ERR_INVALID_APP_CONFIGURATION: app-root element not found');
    }
    appRootElement.style.display = '';
  }

  routerEventsSubscription?: Subscription;

  /**
   This is not used normally, but may be used to debug the timing of the app load, so it shouldn't be removed.
   */
  #timing = {
    constructorInvocationForAppComponent: 0,
    guardCheckStart: 0,
    guardCheckEnd: 0,
  };

  constructor(
    public translateService: TranslateService,
    private router: Router,
  ) {
    this.#timing.constructorInvocationForAppComponent = performance.now();

    SoracomUserConsole.modernTranslationServiceDidLoad(translateService);

    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      // All async route guards for empty route (auth check) will trigger this event, so we can use it to do one-time UI initialization post-app-load:
      if (event instanceof GuardsCheckStart && this.router.url === '/') {
        this.#timing.guardCheckStart = performance.now();
      }
      if ((event instanceof GuardsCheckEnd || event instanceof NavigationCancel) && this.router.url === '/') {
        this.removeInitialLoadingUiState();

        this.#timing.guardCheckEnd = performance.now();

        // And, because we only need to do this once, then never again:
        this.routerEventsSubscription?.unsubscribe();
      }
    });
  }
}
