import { LegacyAny } from '@soracom/shared/core';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { GMDate } from './GMDate';
import { GMTime } from './GMTime';
import { GMNull, GMWeekday } from './GpsMultiunitConfigData';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const DefaultStartTime = '00:00';
export const DefaultEndTime = '23:59';
export const MinimumStartDate = dayjs('1981-04-01');
export const MaximumEndDate = dayjs('2059-12-31');

// I'm not sure why, but manufacturer set this value as default.
export const DefaultDurationForScenario6 = 2160;
export const DefaultDuration = 1440;
export const MinimumDuration = 1;
export const MaximumDuration = 1440;
export const NullDuration = -20000;

export const Weekdays: GMWeekday[] = [
  GMWeekday.SUN,
  GMWeekday.MON,
  GMWeekday.TUE,
  GMWeekday.WED,
  GMWeekday.THU,
  GMWeekday.FRI,
  GMWeekday.SAT,
];

export class GpsMultiunitScenario {
  // @ts-expect-error (legacy code incremental fix)
  startDate: GMDate | GMNull; // C1
  // @ts-expect-error (legacy code incremental fix)
  endDate: GMDate | GMNull; // C2
  // @ts-expect-error (legacy code incremental fix)
  startTime: GMTime | GMNull; // C3
  // @ts-expect-error (legacy code incremental fix)
  endTime: GMTime | GMNull; // C4
  // @ts-expect-error (legacy code incremental fix)
  duration: number; // C5
  // @ts-expect-error (legacy code incremental fix)
  weekdays: Array<GMWeekday | GMNull> | GMNull; // C6

  /**
   * Start Date
   */
  get C1(): string {
    return this.startDate.toString();
  }
  set C1(val: string) {
    this.startDate = GMDate.parse(val);
  }
  /**
   * End Date
   */
  get C2(): string {
    return this.endDate.toString();
  }

  set C2(val: string) {
    this.endDate = GMDate.parse(val);
  }

  /**
   * Start Time
   */
  get C3(): string {
    return this.startTime.toString();
  }

  set C3(val: string) {
    this.startTime = GMTime.parse(val, DefaultStartTime);
  }

  /**
   * End Time
   */
  get C4(): string {
    return this.endTime.toString();
  }

  set C4(val: string) {
    this.endTime = GMTime.parse(val, DefaultEndTime);
  }

  /**
   * Duration
   */
  get C5(): number {
    return this.duration;
  }

  set C5(val: number) {
    if (val === NullDuration || val === DefaultDurationForScenario6) {
      this.duration = val;
    } else if (val >= MinimumDuration && val <= MaximumDuration) {
      this.duration = val;
    } else {
      this.duration = DefaultDuration;
    }
  }

  /**
   * Array of weekdays that enables scheduled tracking.
   *
   * The length should be 7.
   * Inactive weekdays should be "null".
   *
   */
  get C6(): string[] | GMNull {
    if (this.weekdays === 'null') {
      return 'null';
    } else {
      return this.weekdays.map((v) => (v !== 'null' ? v.toString() : 'null'));
    }
  }

  set C6(val: string[] | GMNull) {
    if (val !== 'null' && isValidWeekdays(val)) {
      this.weekdays = val.map((v) =>
        v === 'null' ? 'null' : (GMWeekday as LegacyAny)[v],
      );
    } else {
      this.weekdays = 'null';
    }
  }

  static generateUnused() {
    return new GpsMultiunitScenario(
      'null',
      'null',
      'null',
      'null',
      -20000,
      'null',
    );
  }

  constructor(
    C1: string,
    C2: string,
    C3: string,
    C4: string,
    C5: number,
    C6: string[] | GMNull,
  ) {
    this.C1 = C1;
    this.C2 = C2;
    this.C3 = C3;
    this.C4 = C4;
    this.C5 = C5;
    this.C6 = C6;
  }

  /**
   *
   * @param weekday 0 is SUN, 1 is MON... 0 to 6.
   */
  isEnabledOnWeekday(weekday: number): boolean {
    if (this.weekdays === 'null') {
      return false;
    } else {
      return this.weekdays[weekday] === Weekdays[weekday];
    }
  }

  enableOnWeekday(weekday: number, enabled: boolean) {
    if (weekday < 0 && weekday > 7) {
      throw new Error('Invalid weekday. It should be 0 - 6');
    }

    if (this.weekdays === 'null') {
      if (enabled) {
        this.weekdays = [
          'null',
          'null',
          'null',
          'null',
          'null',
          'null',
          'null',
        ];
        this.weekdays[weekday] = Weekdays[weekday];
      }
    } else {
      this.weekdays[weekday] = enabled ? Weekdays[weekday] : 'null';
    }
  }

  validate(): boolean {
    return (
      this.validateStartDate() &&
      this.validateEndDate() &&
      this.validateStartTime() &&
      this.validateEndTime() &&
      this.validateDateRange() &&
      this.validateTimeRange() &&
      this.validateDuration() &&
      this.validateWeekdays()
    );
  }

  validateDateRange(): boolean {
    return this.startDate < this.endDate;
  }

  validateTimeRange(): boolean {
    return this.startTime < this.endTime;
  }

  validateStartDate(): boolean {
    if (this.startDate instanceof GMDate) {
      return this.startDate.dayjs.isSameOrAfter(MinimumStartDate);
    } else {
      return this.startDate === 'null';
    }
  }

  validateEndDate(): boolean {
    if (this.endDate instanceof GMDate) {
      return this.endDate.dayjs.isSameOrBefore(MaximumEndDate);
    } else {
      return this.endDate === 'null';
    }
  }

  validateStartTime(): boolean {
    if (this.startTime instanceof GMTime) {
      return this.startTime.isValid();
    } else {
      return this.startTime === 'null';
    }
  }

  validateEndTime(): boolean {
    if (this.endTime instanceof GMTime) {
      return this.endTime.isValid();
    } else {
      return this.endTime === 'null';
    }
  }

  validateDuration(): boolean {
    if (
      this.duration === NullDuration ||
      this.duration === DefaultDurationForScenario6
    ) {
      return true;
    } else {
      return (
        Number(this.duration) === this.duration &&
        this.duration >= MinimumDuration &&
        this.duration <= MaximumDuration
      );
    }
  }

  validateWeekdays(): boolean {
    if (this.weekdays === 'null') {
      return true;
    } else {
      return isValidWeekdays(this.weekdays);
    }
  }
}

export function isNoDateLimit(scenario: GpsMultiunitScenario): boolean {
  const startDate = scenario.startDate;
  const endDate = scenario.endDate;
  return (
    (startDate === 'null' || startDate.dayjs.diff(MinimumStartDate) === 0) &&
    (endDate === 'null' || endDate.dayjs.diff(MaximumEndDate) === 0)
  );
}

export function isNoWeekdaysLimit(scenario: GpsMultiunitScenario): boolean {
  return (
    scenario.weekdays === 'null' ||
    scenario.weekdays.every((val, idx) => val === Weekdays[idx])
  );
}

export function isNoTimeLimit(scenario: GpsMultiunitScenario): boolean {
  const startTime = scenario.startTime;
  const endTime = scenario.endTime;
  return (
    (startTime === 'null' || startTime.toString() === DefaultStartTime) &&
    (endTime === 'null' || endTime.toString() === DefaultEndTime)
  );
}

export function isValidWeekdays(val: string[]): boolean {
  if (val.length !== 7) {
    return false;
  }

  for (let i = 0; i < 6; i++) {
    if (val[i] !== 'null' && val[i] !== Weekdays[i]) {
      return false;
    }
  }

  return true;
}
