import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { HarvestDataDownloadModalComponent } from '../harvest-data-download-modal/harvest-data-download-modal.component';
import { HarvestDataStore } from '../harvest-data.store';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-harvest-data-download-button',
  templateUrl: './harvest-data-download-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: inline;
      }
    `,
  ],
})
export class HarvestDataDownloadButtonComponent {
  #modalService = inject(UiDsModalService);
  #svc = inject(HarvestDataStore);
  disabled = toSignal(this.#svc.numResources$.pipe(map((n) => n === 0)), { initialValue: true });

  openDialog() {
    this.#modalService.open(HarvestDataDownloadModalComponent, {
      title: 'HarvestData.download.heading',
      data: { resources: this.#svc.resources, timeRange: this.#svc.timeRange },
    });
  }
}
