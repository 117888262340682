import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Alert, AlertsComponent } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { Group } from '@soracom/shared/group';
import { EditTagsAction } from '../../feature-tags';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { TagsModule } from 'libs/shared-ng/ui-soracom/src/lib/tags';

@Component({
  selector: 'app-group-tags-editor',
  template: `
    @if (group) {
      <soracom-tags-table excludeName [tags]="group.tags" />
      <button class="ds-button --primary" (click)="onEdit()">
        <span>{{ 'common.edit' | translate }}</span>
      </button>
    }
    <app-alerts />
  `,
  host: { class: 'ds-rows' },
  standalone: true,
  imports: [TranslateModule, AlertsComponent, TagsModule],
  providers: [AlertsManager],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupTagsEditorComponent {
  #alertsManager = inject(AlertsManager);
  #cdRef = inject(ChangeDetectorRef);
  #editTags = new EditTagsAction(groupsService, { excludeName: true });

  @Input() group?: Group;

  async onEdit() {
    if (!this.group) {
      return;
    }
    const { updated } = await this.#editTags.run(this.group);
    // TODO: this is workaround before Angular migration. After migration, the groups-details.component would be responsible for updating the group
    if (updated) {
      try {
        const newGroup = await groupsService.get(this.group.id);
        this.group = newGroup;
      } catch (e) {
        this.#alertsManager.add(Alert.fromApiError(e));
      } finally {
        this.#cdRef.markForCheck();
      }
    }
  }
}
